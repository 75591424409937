import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <h5>Links</h5>
          <ul class="footer-nav list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div class="col-sm">
          <h5>License</h5>
          <p>
            NJ Master Plumber License #6574
            <br />
            NJ HVAC License #19HC00110500
            <br />
            NJ Contractor License #13VH00047500
          </p>
        </div>
        <div class="col-sm">
          {" "}
          <h5>Contact</h5>
          <a href="tel:2013335314">201-333-5314</a>
          <br />
          <a href="mailto:louguarini@louguarini.com">
            louguarini@louguarini.com
          </a>
          <br />
          240 Wooodward Ave.
          <br />
          Rutherford, NJ 07070
        </div>
      </div>
    </div>

    <div class="copyright text-center">
      © {new Date().getFullYear()}
      {` `}
      Lou Guarini Plumbing & Heating
    </div>
  </div>
)

export default Footer
