import React from "react"
import { Link } from "gatsby"
import logo from "../images/guarini-plumbing-icon.png"

const Header = () => (
  <header>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <Link to="/">
          <btn class="navbar-brand">
            <img
              src={logo}
              alt="jersey city plumber logo"
              width="30"
              height="30"
            />
          </btn>
        </Link>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <Link to="/">
              <li class="nav-item">
                <btn class="nav-link">Home</btn>
              </li>
            </Link>
            <Link to="/services">
              <li class="nav-item">
                <btn class="nav-link">Services</btn>
              </li>
            </Link>
            <Link to="/contact">
              <li class="nav-item">
                <btn class="nav-link">Contact</btn>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </nav>

    <div class="jumbotron-fluid text-center">
      <h1 class="display-4">Lou Guarini Plumbing & Heating</h1>
      <h2 class="slogan">Proudly Serving Jersey City for Four Generations!</h2>
      <p class="call">
        Call Today! <a href="tel:2013335314">201-333-5314</a>
      </p>
    </div>
  </header>
)

export default Header
